module services {
    export module customs {
        export class accountPaymentService implements interfaces.customs.IAccountPaymentService {
            static $inject = ["$resource", "ENV", "$http", "$timeout", "generalService"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig, private $http: ng.IHttpService, private $timeout: ng.ITimeoutService, public generalService: interfaces.applicationcore.IGeneralService) {
            }

            getAccountPayment(accountPaymentId: number): ng.resource.IResourceClass<interfaces.customs.IAccountPaymentMaster> {

                return this.$resource<interfaces.customs.IAccountPaymentMaster>(this.ENV.DSP_URL + "AccountPayment/GetAccountPayment", {
                    accountPaymentId: accountPaymentId
                }, {
                        query: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }

    

            getAvailableCustomsAccountDetail(): ng.resource.IResourceClass<interfaces.customs.IAccountPaymentDetailDisplay> {              

                return this.$resource<interfaces.customs.IAccountPaymentDetailDisplay>(this.ENV.DSP_URL + "AccountPayment/GetAvailableCustomsAccountDetail", {
                    searchCriteria: '@searchCriteria'
                }, {
                        query: {
                            method: 'POST',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                            params: {
                            }
                        }
                    });

            }

          
            save(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "AccountPayment/Save");
            }


        }
    }
    angular.module("app").service("accountPaymentService", services.customs.accountPaymentService);
}